import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-3d-carousel'
import "./index.css"
import Loader2 from './Loader2';

const HomePage = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const [launch, setLaunch] = useState(false);

  useEffect(() => {

    

    const targetDate = new Date("September 9, 2024 00:00:00").getTime();

    const countdown = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        clearInterval(countdown);
        setLaunch(true);
      } else {
        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      }
    }, 1000);

    return () => clearInterval(countdown);
  }, []);

  if (launch) {
    return (
      <div>
        <h1>Welcome to the Website!</h1>
        {/* Yahan aap apni main website ya component render kar sakte hain */}
      </div>
    );
  }

  return (
    <div className="coming-soon">
       <div>
<div class="container-fluid " >
    <div class="logo"></div>

    <div class="timer-container">
      <h1 id="headline" ></h1>
      <div id="countdown">
        <ul>
          <li className='lg:text-2xl  sm:text-xl xs:text-xs'>{timeLeft.days } days</li>
          <li className='lg:text-2xl  sm:text-xl xs:text-xs'>{timeLeft.hours } Hours</li>
          <li className='lg:text-2xl  sm:text-xl xs:text-xs'>{timeLeft.minutes} Minutes</li>
          <li className='lg:text-2xl  sm:text-xl xs:text-xs'>{timeLeft.seconds} Seconds</li>
        </ul>
      </div>
      <div class="text " style={{marginTop:" 30px"}} >
        <h1> Comming Soon</h1>
      </div>
    </div>
    
    <Carousel width='800px' height='550px' depth={1} statusSize='large' showStatus="" indicatorsSize='large'>
       <a href="https://unsplash.com/">
        <img
          src="./A1.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover object-fill"
        />
      </a>
      <a href="https://unsplash.com/">
        <img
          src="./A2.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover list-image-none"
        />
        
      </a>
      <a href="https://unsplash.com/">
        <img
          src="./A3.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a>     <a href="https://unsplash.com/">
        <img
          src="./A4.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a>     <a href="https://unsplash.com/">
        <img
          src="./A5.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a>    
      <a href="https://unsplash.com/">
        <img
          src="./A6.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a>    
      <a href="https://unsplash.com/">
        <img
          src="./A7.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a> 
      <a href="https://unsplash.com/">
        <img
          src="./A8.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a> 
      <a href="https://unsplash.com/">
        <img
          src="./A9.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover "
        />
      </a> 
      <a href="https://unsplash.com/">
        <img
          src="./A10.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a> 
      <a href="https://unsplash.com/">
        <img
          src="./A11.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a> 
      <a href="https://unsplash.com/">
        <img
          src="./A12.JPG"
          alt="sample-image"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          class="w-full h-full object-cover"
        />
      </a> 
    </Carousel>
  
      </div>
    


      
    
    </div>
     
    </div>
  );

}

export default HomePage



