import React, { useEffect, useState } from 'react'
import HomePage from './components/HomePage'
import {BrowserRouter as Router , Routes , Route} from 'react-router-dom'
import Loader2 from './components/Loader2'


const App = () => {

 const [loading , setLoading] = useState(true)

 useEffect(() => {
  // Simulate a loading delay
  setTimeout(() => setLoading(false), 3000);
}, []);

if (loading) {
  return (
  <div>
  <Loader2 />
  </div>
);
}
else{

  
  return (
    <div>

      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
        </Routes>
      </Router>
    </div>
  )
}
  
}

export default App
